import React, { useEffect } from 'react'

import ImageBanner from "../../img/services/desarrollo-de-software.jpg";
import PosComputer from "../../img/proyectos/details/posComputerQPOS.png";
import Header from '../Header';
import { motion, useScroll } from "framer-motion";
import { fadeIn } from "../../variants";
import { Link } from 'react-router-dom';

import Seo from '../seo/Seo';

const Pos = () => {

  const { scrollY } = useScroll();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

/*   const variants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0 }
  };
 */
  return (
    <div className="max-w-[1920px] mx-auto overflow-hidden bg-white">
      
      <Seo title={'Inversiones DS Solutions - POS'} description={'Esta es la pagina Pos'} image={ImageBanner}/>
        {/* <Header />
        <div className=" relative w-full h-64 md:h-96 mt-20"> */}
        <div className=" relative w-full h-64 md:h-96">
            <img 
                src={ImageBanner} 
                alt="" 
                className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <h1 className="text-white text-4xl md:text-6xl font-bold text-center">
                    POS
                </h1>
            </div>
        </div>
        <Link to="/" className="inline-block px-4 py-2 mt-2 mb-2 ml-4 text-white bg-blue-500 rounded hover:bg-blue-600 transition duration-300 ease-in-out">
          Volver al inicio
        </Link>

        <section className="lg:py-16 xl:pb-[160px] bg-gray-100" id="about">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-x-[70px] items-start">
          {/* numbers */}
          <motion.div
            variants={fadeIn("right")}
            initial="hidden"
            whileInView={"show"}
            /* viewport={{ amount: 0.0 }} */
            viewport={{ once: true }}
            
            className="flex flex-col lg:flex-row flex-1"
          >
            <img src={PosComputer} alt="Asesoria tecnica" className="w-full h-auto" />
          </motion.div>
          {/* text */}
          <motion.div
          
            variants={fadeIn("left")}
            initial="hidden"
            whileInView={"show"}

            /* viewport={{ once: false, amount: 0.0 }} */
            viewport={{ once: true, }}
            className="flex-1 h-full xl:mt-5 xs:mt-5 sm:mt-5 xs:text-justify"
          >
            {/* <h2 className="text-4xl font-bold mb-4">Desarrollo de Software</h2> */}
            <div className="flex flex-col items-end">
              <div className="max-w-[630px] text-grey">
                <p className="mb-6"><span className='font-bold'>QPOS</span> es un sistema de facturación integral diseñado para facilitar la gestión de ventas y operaciones comerciales de su empresa o negocio. A continuación, se detallan sus principales características y funcionalidades:</p>

                <h4 className='h4 font-bold'>1.	Facturación Fiscal:</h4>

                <ul className='list-disc ml-6'>
                  <li>Permite la emisión de facturas fiscales cumpliendo con las normativas legales vigentes.</li>
                  <li>Soporta facturación electrónica, lo que facilita el envío y almacenamiento de facturas.</li>
                </ul>

                <h4 className='h4 font-bold'>2.	Control de Inventario:</h4>

                <ul className='list-disc ml-6'>
                  <li>Gestión automatizada del inventario, ajustando los niveles de stock en tiempo real.</li>
                  <li>Notificaciones para reordenar productos cuando los niveles de inventario son bajos.</li>
                </ul>

                <h4 className='h4 font-bold'>3. Multimoneda:</h4>

                <ul className='list-disc ml-6'>
                  <li>Capacidad para manejar transacciones en múltiples monedas, facilitando operaciones Comerciales.</li>
                </ul>

                <h4 className='h4 font-bold'>4.	Integración con Impresora de Tickets:</h4>

                <ul className='list-disc ml-6'>
                  <li>Compatible con impresoras de tickets para la emisión rápida y eficiente de comprobantes de venta.</li>
                </ul>

                <h4 className='h4 font-bold'>5.	Cambio de Precios:</h4>

                <ul className='list-disc ml-6'>
                  <li>Funcionalidad para actualizar precios de productos de manera sencilla y rápida.</li>
                </ul>

                <h4 className='h4 font-bold'>6.	Descuentos y Promociones:</h4>

                <ul className='list-disc ml-6'>
                  <li>Herramientas para aplicar descuentos y gestionar promociones, mejorando la experiencia del cliente y aumentando las ventas.</li>
                </ul>

                <h4 className='h4 font-bold mt-2'>Funcionalidades Adicionales</h4>
                <h4 className='h4 font-bold'>1.	Generación de Libros de Ventas y Compras:</h4>

                <ul className='list-disc ml-6'>
                  <li>Automatización de la generación de libros de ventas y compras, facilitando la contabilidad y el cumplimiento fiscal.</li>
                </ul>

                <h4 className='h4 font-bold mt-2'>2.	Reportes Múltiples:</h4>

                <ul className='list-disc ml-6'>
                  <li><span className='font-bold'>Inventario:</span> Reportes detallados sobre el estado y movimiento del inventario.</li>
                  <li><span className='font-bold'>Ventas:</span> Análisis de ventas por producto, categoría, periodo, etc.</li>
                  <li><span className='font-bold'>Cajas:</span> Control y reportes de las operaciones de caja, incluyendo ingresos y egresos.</li>
                </ul>

                <h4 className='h4 font-bold mt-2'>Beneficios</h4>

                <ul className='list-disc ml-6'>
                  <li><span className='font-bold'>Eficiencia Operativa:</span> Automatiza procesos clave, reduciendo el tiempo y esfuerzo manual.</li>
                  <li><span className='font-bold'>Toma de Decisiones Informada:</span> Proporciona datos y reportes en tiempo real para una mejor toma de decisiones.</li>
                  <li><span className='font-bold'>Cumplimiento Legal:</span> Asegura el cumplimiento de las normativas fiscales y contables.</li>
                  <li><span className='font-bold'>Flexibilidad y Adaptabilidad:</span> Se adapta a diferentes tipos de negocios y necesidades específicas.</li>
                </ul>

                <p className="my-4"><span className='font-bold'>QPOS</span> es una solución robusta y versátil que puede mejorar significativamente la gestión de tu negocio, optimizando tanto las operaciones diarias como la planificación estratégica.</p>


              </div>

            </div>
          </motion.div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default Pos