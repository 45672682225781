import React, { useEffect } from 'react'
import Header from './components/Header'
import Hero from './components/Hero'
import About from './components/About'
import Servicios from './components/Servicios'
import Testimonial from './components/Testimonial'
import Contact from './components/Contact'
import Footer from './components/Footer'
import Copyright from './components/Copyright'
import Seo from './components/seo/Seo'
import Logo from './img/hero/logo.png'
import Proyectos from './components/Proyectos'
import { useScroll } from 'framer-motion'

const Landing = () => {

  const { scrollY } = useScroll();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="max-w-[1920px] mx-auto overflow-hidden bg-white">
      <Seo title={'Inversiones DS Solutions'} description={'pagina de inicio de Inversiones DS Solutions'} image={Logo}/>
        <Header />
        <Hero />
        <About />
        <Servicios />
        <Proyectos />
        <Testimonial />
        <Contact />
        <Footer />
        <Copyright />
    </div>
  )
}

export default Landing