// import images
import Logo from "../src/img/header/logo.svg";
import GalleryImg1 from "../src/img/gallery/1.png";
import GalleryImg2 from "../src/img/gallery/2.png";
import GalleryImg3 from "../src/img/gallery/3.png";
import GalleryImg4 from "../src/img/gallery/4.png";
import GalleryImg5 from "../src/img/gallery/5.png";
import GalleryImg6 from "../src/img/gallery/6.png";
import GalleryImg7 from "../src/img/gallery/7.png";
import GalleryImg8 from "../src/img/gallery/8.png";
import QuoteImg from "../src/img/testimonial/quote.svg";
// import icons
import { GrFacebookOption } from "react-icons/gr";
import { IoMdArrowForward } from "react-icons/io";
import { FiSend } from "react-icons/fi";
import { FaPlay, FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import {
  IoLogoInstagram,
  IoLogoPinterest,
  IoLogoTwitter,
  IoLogoYoutube,
} from "react-icons/io";

// NAVBAR & HEADER SECTION 

export const headerData = {
  logo: Logo,
};

export const navData = {
  items: [
    { href: "/", name: "Inicio"},
    { href: "#about", name: "Nosotros" },
    { href: "#servicios", name: "Servicios" },
    { href: "#contact", name: "Contactanos" },
  ],
};

export const socialData = [
  { href: "", icon: <GrFacebookOption /> },
  { href: "", icon: <IoLogoInstagram /> },
  /* { href: "https://pinterest.com/", icon: <IoLogoPinterest /> }, */
  { href: "", icon: <IoLogoTwitter /> },
  /* { href: "https://youtube.com/", icon: <IoLogoYoutube /> }, */
];

// NAVBAR & HEADER SECTION END
////////////////////////////////////
// HERO SECTION

export const heroData = {
  title: "Quienes Somos?",
  subtitle:
    "Somos una empresa dedicada a soluciones tecnologicas.",
  btnText: null,
  btnIcon: <IoMdArrowForward />,
};

// HERO SECTION END
///////////////////////////////////
// ABOUT SECTION
export const aboutData = {
  title: "Nosotros",
  subtitle1: "Somos un apasionado grupo de desarrolladores de software que decidimos unir nuestras habilidades y conocimientos para crear una sociedad tecnologica innovadora.",
  subtitleMission:"Misión",
  descriptionMission: "Nuestra misión es desarrollar soluciones de software innovadoras y de alta calidad que impulsen la eficiencia y el crecimiento de nuestros clientes. Nos comprometemos a ofrecer productos y servicios que no solo satisfagan las necesidades actuales del mercado, sino que también anticipen y respondan a las tendencias futuras. Trabajamos con pasión y dedicación para crear tecnología que transforme la manera en que las empresas operan y se conectan con sus clientes.",
  subtitleVission:"Visión",
  descriptionVission: "Nuestra visión es ser líderes globales en el desarrollo de software, reconocidos por nuestra capacidad de innovación, excelencia técnica y compromiso con la satisfacción del cliente. Aspiramos a construir un futuro donde nuestras soluciones tecnológicas sean fundamentales para el éxito de las empresas en todo el mundo, promoviendo un entorno de trabajo colaborativo y creativo que inspire a nuestros empleados a alcanzar su máximo potencial.",
  btnText: null,
  btnIcon: <IoMdArrowForward />,
};
// ABOUT SECTION END
/////////////////////////////////////////////////
// GALLERY SECTION
export const galleryData = {
  title: "Gallery",
  btnText: "View all",
  href: "/gallery",
  btnIcon: <IoMdArrowForward />,
  images: [
    {
      src: GalleryImg1,
      original: GalleryImg1,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg2,
      original: GalleryImg2,
      width: 465,
      height: 540,
    },
    {
      src: GalleryImg3,
      original: GalleryImg3,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg4,
      original: GalleryImg4,
      width: 465,
      height: 540,
    },
    {
      src: GalleryImg5,
      original: GalleryImg5,
      width: 465,
      height: 540,
    },
    {
      src: GalleryImg6,
      original: GalleryImg6,
      width: 464,
      height: 412,
    },
    {
      src: GalleryImg7,
      original: GalleryImg7,
      width: 465,
      height: 540,
    },
    {
      src: GalleryImg8,
      original: GalleryImg8,
      width: 465,
      height: 412,
    },
  ],
};
// GALLERY SECTION END
////////////////////////////////////////////////////////////////
// INTERVIEW SECTION
export const interviewData = {
  title:
    "“Consider what you desire. Your tattoo artist will never tell you what tattoo to have.”",
  btnText: "Watch it now",
  btnIcon: <FaPlay />,
};
// INTERVIEW SECTION END
////////////////////////////////////////////////////////////////
// TESTIMONIAL SECTION
export const testimonialData = [
  {
    quoteImg: QuoteImg,
    message:
      "The entire team is extremely kind and friendly. They're fantastic. They're great at what they do! And it's unique.They will properly consult with you.",
    name: "John Doe",
    occupation: "Tattoo Artist",
  },
  {
    quoteImg: QuoteImg,
    message:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam culpa asperiores officia natus dignissimos autem possimus molestias aperiam?",
    name: "Harold Wren",
    occupation: "Tattoo Artist",
  },
];
// TESTIMONIAL SECTION END
////////////////////////////////////////////////////////////////////////
// CONTACT SECTION
export const contactData = {
  title: "Contactanos",
  info: [
    {
      title: "Inversiones Ds Solutions",
      subtitle:
        "Tu solucion tecnologica al alcance de tus manos",
      address: {
        icon: <FaMapMarkerAlt />,
        name: "Calle Boyaca, Centro de oficinas Uno, piso 7 Ofic 73, Maracay - Venezuela",
      },
      phone: {
        icon: <FaPhoneAlt />,
        number: "+58 (424) 368 52-00 / +58 (412) 883 26-03",
      },
      email: {
        icon: <FaEnvelope />,
        address: "ventas@inversiones-ds.com",
      },
      link: "Get location",
    },    
  ],
  form: {
    name: "Su nombre",
    email: "Su correo electronico",
    message: "Su mensaje",
    btnText: "Enviar",
  },
};
// CONTACT SECTION END
////////////////////////////////////////////////////////////////////////
// FOOTER SECTION
export const footerData = {
  about: {
    title: "Nosotros",
    subtitle:
      "Somos un apasionado grupo de desarrolladores de software que decidimos unir nuestras habilidades y conocimientos para crear una sociedad tecnologica innovadora.",
    address: {
      icon: <FaMapMarkerAlt />,
      name: "Calle Boyaca, Centro de oficinas Uno, piso 7 Ofic 73, Maracay - Venezuela",
    },
    phone: {
      icon: <FaPhoneAlt />,
      number: "+58 (424) 368 52-00 / +58 (412) 883 26-03",
    },
    email: {
      icon: <FaEnvelope />,
      address: "ventas@inversiones-ds.com",

    },
  },
  links: {
    title: "Enlaces",
    items: [
      { href: "#about", name: "Nosotros" },
      { href: "#contact", name: "Contactanos" },
    ],
  },
  program: {
    title: "Working Time",
    items: [
      { name: "Mon - Tue / Appointment" },
      { name: "Wed - Fri / 10:00 - 9:00pm" },
      { name: "Sat / 10:00 - 6:00pm" },
      { name: "Sun / no work on this day" },
    ],
  },
  newsletter: {
    title: "Newsletter",
    subtitle:
      "Elit duis porttitor massa tellus nun in velit arcu posuere integer.",
    form: {
      placeholder: "Your email address",
      icon: <FiSend />,
    },
  },
};
// FOOTER SECTION END