import React, { useEffect } from 'react'

import ImageBanner from "../../img/services/desarrollo-de-software.jpg";
import CreditosPerson from "../../img/proyectos/details/creditosperson.jpg";
import Header from '../Header';
import { motion, useScroll } from "framer-motion";
import { fadeIn } from "../../variants";
import { Link } from 'react-router-dom';

import Seo from '../seo/Seo';
import { FaClipboardList, FaHandHoldingUsd, FaRegCreditCard } from 'react-icons/fa';

const SistemaCreditos = () => {

  const { scrollY } = useScroll();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

/*   const variants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0 }
  };
 */
  return (
    <div className="max-w-[1920px] mx-auto overflow-hidden bg-white">
      
      <Seo title={'Inversiones DS Solutions - Sistema de creditos'} description={'Esta es la pagina de desarrollo de software'} image={ImageBanner}/>
        {/* <Header />
        <div className=" relative w-full h-64 md:h-96 mt-20"> */}
        <div className=" relative w-full h-64 md:h-96">
            <img 
                src={ImageBanner} 
                alt="" 
                className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <h1 className="text-white text-4xl md:text-6xl font-bold text-center">
                    Sistema de Créditos
                </h1>
            </div>
        </div>
        <Link to="/" className="inline-block px-4 py-2 mt-2 mb-2 ml-4 text-white bg-blue-500 rounded hover:bg-blue-600 transition duration-300 ease-in-out">
          Volver al inicio
        </Link>

        <section className="lg:py-16 xl:pb-[160px] bg-gray-100" id="about">
      
        <div className="flex flex-col lg:flex-row justify-center gap-6 lg:mb-5">
          {/* numbers */}
          <motion.div
            variants={fadeIn("up")}
            initial="hidden"
            whileInView={"show"}
            /* viewport={{ amount: 0.0 }} */
            viewport={{ once: true, }}
            
            className="flex flex-col lg:flex-row"
          >
            {/* <img src={CreditosPerson} alt="Asesoria tecnica" className="w-full h-auto" /> */}
            {/* Card 1 */}
      <div className="max-w-sm rounded overflow-hidden shadow-lg p-4">
        <div className="flex justify-center mb-4">
          <FaRegCreditCard className="text-4xl brown-500" />
        </div>
        <h2 className="font-bold text-xl mb-2 text-center">Control de ventas a crédito</h2>
        <p className="text-gray-700 text-base text-center">
        Gestiona de manera eficiente tus ventas a crédito con nuestro avanzado control de cuotas y fechas de vencimiento, asegurando un seguimiento preciso de los pagos de tus clientes.
        </p>
      </div>

      {/* Card 2 */}
      <div className="max-w-sm rounded overflow-hidden shadow-lg p-4">
        <div className="flex justify-center mb-4">
          <FaHandHoldingUsd className="text-4xl text-brown-500" />
        </div>
        <h2 className="font-bold text-xl mb-2 text-center">Flexibilidad en términos de pago</h2>
        <p className="text-gray-700 text-base text-center">
        Establece términos de pago personalizados y define cuotas y fechas de vencimiento específicas para cada transacción, adaptándote a las necesidades de tus clientes.
        </p>
      </div>

      {/* Card 3 */}
      <div className="max-w-sm rounded overflow-hidden shadow-lg p-4">
        <div className="flex justify-center mb-4">
          <FaClipboardList className="text-4xl text-brown-500" />
        </div>
        <h2 className="font-bold text-xl mb-2 text-center">Registro detallado de pagos</h2>
        <p className="text-gray-700 text-base text-center">
        Mantén un registro detallado de los pagos recibidos, facilitando la gestión de tus finanzas y brindando transparencia a tus clientes.
        </p>
      </div>
          </motion.div>

        </div>


        {/* <motion.div
            variants={fadeIn("left")}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, }}
            className="flex-1 h-full xl:mt-5 xs:mt-5 sm:mt-5 xs:text-justify"
          >
            <div className="flex flex-col items-end">
              <div className="max-w-[530px] text-grey text-justify">
                <h4 className='h4 font-bold sm:text-3xl my-4'>Gestión de ventas a crédito</h4>
                <p className="mb-6">Nuestro servicio de gestión de ventas a crédito te permite llevar un control preciso de tus transacciones comerciales. Podrás establecer términos de pago personalizados, definir cuotas y fechas de vencimiento específicas para cada cliente. Además, podrás generar informes detallados que te ayudarán a evaluar tu rendimiento y tomar decisiones estratégicas. Con nuestra solución, podrás maximizar tus ingresos y minimizar el riesgo de impagos.</p>
              </div>
            </div>
          </motion.div> */}
          <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-x-[30px] items-center">
          {/* numbers */}
          <motion.div
            variants={fadeIn("right")}
            initial="hidden"
            whileInView={"show"}
            /* viewport={{ amount: 0.0 }} */
            viewport={{ once: true, }}
            
            className="flex flex-col lg:flex-row flex-1"
          >
            <img src={CreditosPerson} alt="persona creditos" className="w-full h-auto" />
          </motion.div>
          {/* text */}
          <motion.div
            variants={fadeIn("left")}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, }}
            className="flex-1 h-full xl:mt-5 xs:mt-5 sm:mt-5 xs:text-justify"
          >
            <div className="flex flex-col items-end">
              <div className="max-w-[630px] text-grey text-justify">
                <h4 className='h4 font-bold sm:text-3xl my-4'>Gestión de ventas a crédito</h4>
                <p className="mb-6">Nuestro servicio de gestión de ventas a crédito te permite llevar un control preciso de tus transacciones comerciales. Podrás establecer términos de pago personalizados, definir cuotas y fechas de vencimiento específicas para cada cliente. Además, podrás generar informes detallados que te ayudarán a evaluar tu rendimiento y tomar decisiones estratégicas. Con nuestra solución, podrás maximizar tus ingresos y minimizar el riesgo de impagos.</p>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
      
    </section>
    </div>
  )
}

export default SistemaCreditos