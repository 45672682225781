import React from "react";
//  useInView hook
import { useInView } from "react-intersection-observer";
// ml-4 import motion
import { motion } from "framer-motion";
// import variants
import { fadeIn } from "../variants";
import creditos from "../img/proyectos/creditos.png";
import estadistica from "../img/proyectos/estadistica.jpg";
import DesarrolloWeb from "../img/services/desarrolloweb.png";
import PosWithLector from "../img/proyectos/details/posComputerQPOS.png";
import AsesoriaTecnica from "../img/services/asesoriatecnica.jpg";
import { Link } from "react-router-dom";

const Proyectos = () => {
  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  return (
    <section className="section-services pt-32 bg-white" id="servicios">
    <motion.section
      variants={fadeIn("up")}
      initial="hidden"
      whileInView={"show"}
      viewport={{ once: false, amount: 0.1 }}
      ref={ref}
      className="font-primary"
    >

      <h3 className="text-3xl font-bold text-center mb-8 text-gray-800">
        Proyectos
      </h3>

      <div className="container mx-auto">
        <div className="flex flex-col xl:flex-row justify-center items-center gap-y-12">
          
          <div className="lg:w-[275px] sm:w-full flex flex-col justify-center items-center gap-y-6">
          <Link to='/pos' className="block w-full">
            <div className="max-w-sm rounded overflow-hidden shadow-lg">
              <img className="w-full h-48 object-cover" src={PosWithLector} alt="pos" />
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2 text-center">POS</div>
                <p className="text-gray-700 text-base xs:text-justify lg:text-justify mb-8">
                Sistema de facturacion adaptado a tu negocio.
                </p>
              </div>
            </div>
            </Link>
          </div>
          {/* w-[150px] */}
          <div className=" lg:w-[275px] ml-4 sm:w-full flex flex-col justify-center items-center gap-y-6">
          <Link to='/sistema-creditos' className="block w-full">
            <div className="max-w-sm rounded overflow-hidden shadow-lg">
              <img className="w-full h-48 object-cover" src={creditos} alt="Sistema de creditos" />
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">Sistema de creditos</div>
                <p className="text-gray-700 text-base xs:text-justify lg:text-justify mb-4">
                Nuestro sistema de creditos te va ayudar lo suficiente para la gestion de tu negocio.
                </p>
              </div>
            </div>
            </Link>
          </div>

          <div className=" lg:w-[275px] ml-4 sm:w-full flex flex-col justify-center items-center gap-y-6">
          <Link to='/estadistico' className="block w-full">
            <div className="max-w-sm rounded overflow-hidden shadow-lg">
              <img className="w-full h-48 object-cover" src={estadistica} alt="Portal estadistico" />
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">Dashboard de ventas</div>
                <p className="text-gray-700 text-base xs:text-justify lg:text-justify">
                Nuestro dashboard de ventas es una herramienta integral diseñada para proporcionar una visión completa y detallada de tu empresa.
                </p>
              </div>
            </div>
            </Link>
          </div>

        </div>
      </div>
    </motion.section>  
      </section>
    
  );
};

export default Proyectos;
