import React, { useEffect } from 'react'

import ImageBanner from "../../img/proyectos/details/Estadistica-calc.jpg";
import CreditosPerson from "../../img/proyectos/details/Estadisticas.jpg";
import Header from '../Header';
import { motion, useScroll } from "framer-motion";
import { fadeIn } from "../../variants";
import { Link } from 'react-router-dom';

import Seo from '../seo/Seo';

const PortalEstadistico = () => {

  const { scrollY } = useScroll();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

/*   const variants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0 }
  };
 */
  return (
    <div className="max-w-[1920px] mx-auto overflow-hidden bg-white">
      
      <Seo title={'Inversiones DS Solutions - Dashboard de ventas'} description={'Esta es la pagina de portal estadistico'} image={ImageBanner}/>
        {/* <Header />
        <div className=" relative w-full h-64 md:h-96 mt-20"> */}
        <div className=" relative w-full h-64 md:h-96">
            <img 
                src={ImageBanner} 
                alt="" 
                className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <h1 className="text-white text-4xl md:text-6xl font-bold text-center">
                    Dashboard de ventas
                </h1>
            </div>
        </div>
        <Link to="/" className="inline-block px-4 py-2 mt-2 mb-2 ml-4 text-white bg-blue-500 rounded hover:bg-blue-600 transition duration-300 ease-in-out">
          Volver al inicio
        </Link>

        <section className="lg:py-16 xl:pb-[160px] bg-gray-100" id="about">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-x-[70px] items-start">
          {/* numbers */}
          <motion.div
            variants={fadeIn("right")}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.1 }}

            className="flex flex-col lg:flex-row flex-1"
          >
            {/* <div className="text-[260px] xl:text-[520px] leading-none font-tertiary lg:-tracking-[0.055em] bg-about bg-no-repeat bg-right bg-clip-text text-transparent">
              08
            </div> */}
            <img src={CreditosPerson} alt="Desarrollo web" className="w-full h-auto" />
          </motion.div>

          <motion.div
            variants={fadeIn("left")}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.1 }}
            className="flex-1 h-full xl:mt-5 xs:mt-5 sm:mt-5 xs:text-justify"
          >
            {/* <h2 className="text-4xl font-bold mb-4">Desarrollo Web</h2> */}
            <div className="flex flex-col items-end ">
              <div className="max-w-[530px] text-grey">
                <p className="mb-6">Nuestro dashboard de ventas es una herramienta integral diseñada para proporcionar una visión completa y detallada del rendimiento de ventas de tu empresa. Con una interfaz intuitiva y fácil de usar, este dashboard permite a los usuarios visualizar y analizar datos clave de ventas de manera eficiente.</p>

                <h4 className='h4 font-bold'>Características Principales:</h4>

                <ul className='list-disc ml-6'>
                  <li><span className='font-bold'>Ventas por Rango de Fecha:</span> Filtra y visualiza las ventas realizadas en periodos específicos, permitiendo un análisis temporal detallado.</li>
                  <li><span className='font-bold'>Ventas por Rubro:</span> Clasifica y muestra las ventas según diferentes categorías de productos o servicios, facilitando la identificación de los rubros más rentables.</li>
                  <li><span className='font-bold'>Total Facturado por Tipo de Moneda:</span> Presenta el total de las ventas facturadas, desglosado por tipo de moneda, para una mejor comprensión de los ingresos en diferentes divisas.</li>
                  <li><span className='font-bold'>Cobros por Tipo de Moneda:</span> Muestra los montos cobrados según el tipo de moneda, tanto en totales como en detalle, permitiendo un seguimiento preciso de los pagos recibidos.</li>
                </ul>

                <p className='mt-3 mb-4'>
                Este dashboard es una herramienta esencial para cualquier equipo de ventas que busque optimizar su rendimiento y tomar decisiones basadas en datos precisos y actualizados.
                </p>

              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default PortalEstadistico